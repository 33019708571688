import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@scuf/common';

const AppModal = (props) => {
  const { handleToggle, isOpen, content, handleSubmit } = props;
  return (
    <Modal closeIcon onClose={handleToggle} open={isOpen} size="small">
      <Modal.Content>{content}</Modal.Content>
      <Modal.Footer>
        <Button type="primary" size="small" content="Close" onClick={handleToggle} />
        <Button type="primary" size="small" content="Submit" onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
};

AppModal.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
};

export default AppModal;
