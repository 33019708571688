const Routes = {
  Home: '/home',
  Vad: '/vad',
  Task: '/task',
  TaskHistory: '/task/history',
  Rules: '/rules',
  CreateRule: '/rules/create',
  EditRule: '/rules/edit',
  History: '/history',
  CreateSLA: '/sla/create',
  ViewSLA: '/sla/view',
  SLATable: '/sla/status',
  SLASubmit: '/sla/submit',
  SLASuccess: '/sla/success',
  SLAInvalidLink: '/sla/invalid',
  ViewConfiguration: '/admin/configuration',
};

export default Routes;
