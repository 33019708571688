import React from 'react';
import { Card, Notification } from '@scuf/common';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card>
        <Notification hasIcon severity="information">
          Select an option from the side menu.
        </Notification>
      </Card>
    );
  }
}

export default Home;
