import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon, SidebarLayout } from '@scuf/common';
import { VadConsumer } from '../pages/vad/VadContext';
import Routes from '../common/routes';
import AppModal from './AppModal';

const { Sidebar } = SidebarLayout;

const inActiveStyle = {
  pointerEvents: 'none',
  cursor: 'default',
  textDecoration: 'none',
  color: 'lightgrey',
};

const RuleMenu = (props) => (
  <VadConsumer>
    {(vadContext) => {
      return (
        <Sidebar.Submenu
          content="Rules"
          open={props.location.pathname.includes(Routes.Rules)}
          onClick={() => {
            props.history.push(Routes.Rules);
          }}
          active={props.location.pathname.includes(Routes.Rules)}
          icon={<Icon root="common" name="adjustments" />}
        >
          <Sidebar.Item
            content="Create Rule"
            onClick={() => {
              props.history.push(Routes.CreateRule);
            }}
            active={props.location.pathname === Routes.CreateRule}
            style={
              props.location.pathname === Routes.Rules ||
              props.location.pathname === Routes.CreateRule
                ? null
                : inActiveStyle
            }
          />
          <Sidebar.Item
            content="Edit Rule"
            onClick={() => {
              props.history.push({
                pathname: Routes.EditRule,
                state: vadContext.selectedRows[0],
              });
            }}
            active={props.location.pathname === Routes.EditRule}
            style={
              (props.location.pathname === Routes.Rules ||
                props.location.pathname === Routes.EditRule) &&
              vadContext.selectedRows.length === 1
                ? null
                : inActiveStyle
            }
          />
          <Sidebar.Item
            content="Delete Rule"
            onClick={() => {
              vadContext.toggleConfirmDeleteUserModal();
            }}
            active={props.location.pathname === Routes.DeleteUser}
            style={
              props.location.pathname === Routes.Rules && vadContext.selectedRows.length > 0
                ? null
                : inActiveStyle
            }
          />
          <AppModal
            isOpen={vadContext.isConfirmDeleteModalOpen}
            content="Would you like to delete the selected Rule?"
            handleSubmit={() => vadContext.deleteRules()}
            handleToggle={() => vadContext.toggleConfirmDeleteUserModal()}
          />
        </Sidebar.Submenu>
      );
    }}
  </VadConsumer>
);

RuleMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(RuleMenu);
